import React from 'react'
import LoginCard from '../components/LoginCard'
import Importer from '../components/Importer'
import Layout from '../components/Layout'
import ReactGA from 'react-ga';
import ZoneAPI from '../api/ZoneAPI'
import UtilsManager from '../managers/UtilsManager'


const USE_SOCIAL_LOGIN_KEY = "use_social_login"
const USE_APPLE_LOGIN_KEY = "use_apple_login"
export default class Login extends React.Component { 

	constructor(props) { 
		super(props)
		this.state = {}
		this.utilsManager = new UtilsManager();
	}

	static async getInitialProps(context) { 
		const destination = context.query.dest;
		const type = context.query.type;
		const seatId = context.query.seat_id;
		const zoneApi = new ZoneAPI();
		const zoneResp = await zoneApi.getConfig("client");
		var destinationStr = ''

		if (destination !== undefined) { 
			destinationStr = destination.toString();
		}

		return {dest: '/' + destinationStr, type: type, seatId: seatId, config: zoneResp }
	}

	componentDidMount() { 
		ReactGA.initialize('UA-139931598-1')
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	render() { 
		return (<Layout noBackground={true}>
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:50, marginTop: 100}}>
			
				<h2>Login to Your Account</h2>
			
			<p style={{textAlign: 'center'}}>Register an account</p>
				<LoginCard
				destination={this.props.dest}
				type={this.props.type}
				seatId={this.props.seatId}
				useSocialLogin={this.utilsManager.tryOrDefault(() => this.props.config[USE_SOCIAL_LOGIN_KEY], false)}
				useAppleLogin={this.utilsManager.tryOrDefault(() => this.props.config[USE_APPLE_LOGIN_KEY], false)}
				>
				</LoginCard>
			{
				this.props.dest === "/spaces" || this.props.dest === "/curation" || this.props.type === "login" || this.props.type === undefined ? 
				<p style={{ fontSize: '85%', marginTop: 20}}> Don't have an account? Create one <a href={`/signup?type=signup&dest=${this.props.dest.slice(1)}`}> here</a></p>: 
				<p style={{ fontSize: '85%', marginTop: 20 }}> Already have an account? Sign in <a href={`/signup?type=login&dest=${this.props.dest.slice(1)}`}>here</a></p>
			}
			</div>
			</Layout>
			)
	}
}
